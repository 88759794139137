import React from 'react'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
import CookieConsent from "react-cookie-consent";


const CookieConsentBlock = () => (
    <CookieConsent
        enableDeclineButton
        location="bottom"
        buttonText="Aceptar"
        buttonStyle={{fontSize: "1rem", padding: "0.7rem 1.8rem", borderRadius: "2rem"}}
        declineButtonText="Rechazar"
        declineButtonStyle={{fontSize: "1rem", padding: "0.7rem 1.8rem", borderRadius: "2rem"}}
        onDecline={() => {
            console.info('Cookies rejected');
        }}
        buttonClasses="button-group-button level-1"
        cookieName="gatsby-gdpr-google-analytics">

        Las cookies de este sitio web se usan para personalizar el contenido y los anuncios, ofrecer funciones de redes
        sociales y analizar el tráfico.
    </CookieConsent>
)

export default WithDefaultContent(CookieConsentBlock)
