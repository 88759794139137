/**
 *
 * These styles are solely for adding [background images] or
 * [background colors] to blocks.
 *
 */

import circle from './assets/circle.png'
import bg from './assets/skyscrapers.png'

export default {
  tabsContainer: {
    position: `relative`,
    py: [4, 5],
    '::before': {
      position: `absolute`,
      content: `" "`,
      size: `full`,
      top: 0,
      right: 0,
      zIndex: -1,
      borderRadius: `xl`,
      background: `linear-gradient(
        180deg,
        #f7f9fe 0%,
        #f4f4f8 100%
      )`
    }
  },
  heroContainer: {
    position: `relative`,
    pt: 5,
    '::before': {
      content: `" "`,
      width: `110%`,
      height: `800px`,
      position: `absolute`,
      top: `-130px`,
      zIndex: -1,
      borderRadius: `xl`,
      background: `url(${bg}) no-repeat center center`,
      backgroundSize: `cover`,
    },
    '::after': {
      content: `" "`,
      position: `absolute`,
      top: `50%`,
      left: `50%`,
      transform: `translate(-50%, -45%)`,
      zIndex: -1,
      size: 'full',
      maxWidth: 600,
      maxHeight: 600,
      background: `url(${circle}) no-repeat center center`,
      backgroundSize: `contain`,
      opacity: 0.1
    }
  },
  heroContainerVideo: {
    position: `relative`,
    pt: 5,
    '::before': {
      content: `" "`,
      width: `110%`,
      height: `800px`,
      position: `absolute`,
      top: `-130px`,
      zIndex: -1,
      backgroundSize: `cover`,
    },
    '::after': {
      content: `" "`,
      position: `absolute`,
      top: `50%`,
      left: `50%`,
      transform: `translate(-50%, -55%)`,
      zIndex: -1,
      size: 'full',
      maxWidth: `100%`,
      maxHeight: 600,
      opacity: 0.1
    }
  },
  featuresContainer: {
    position: `relative`,
    overflow: [`hidden`, `unset`],
    '::before': {
      content: `" "`,
      width: `100%`,
      height: [`80%`, null, `140%`],
      position: `absolute`,
      top: [`5%`, null, `-5%`],
      left: `60%`,
      zIndex: -1,
      borderRadius: `xl`,
      transform: `skew(23deg, 0deg) rotate(-10deg)`,
      backgroundSize: `100%`,
      backgroundPosition: `960px bottom`
    },
    '::after': {
      display: [`none`, `block`],
      content: `" "`,
      position: `absolute`,
      top: `-30%`,
      left: `100%`,
      transform: `translate(-50%, 0)`,
      zIndex: -1,
      size: '600px',
      background: `url(${circle}) no-repeat center center`,
      backgroundSize: `cover`,
      filter: `brightness(60%) sepia(100) saturate(100) hue-rotate(25deg)`,
      opacity: 0.1
    }
  },
  socialProofContainer: {
    position: `relative`,
    '::before': {
      content: `" "`,
      width: `100%`,
      height: `100%`,
      position: `absolute`,
      top: 0,
      left: 0,
      zIndex: -2,
      bg: `#F6F7FA`
    }
  },
  ourServicesContainer: {
    position: `relative`,
    '::before': {
      content: `" "`,
      width: `100%`,
      height: `100%`,
      position: `absolute`,
      top: 0,
      left: 0,
      zIndex: -2,
      bg: `01288b`,
      background: t => `linear-gradient(
        145deg,
        ${t.colors.alpha} 0%,
        ${t.colors.alphaDarker} 100%
      )`
    }
  }
}
