import React from 'react'
import {Container, Embed} from 'theme-ui'
import ContentText from '@solid-ui-components/ContentText'
import Reveal from '@solid-ui-components/Reveal'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
import styles from '../../pages/_styles'
import videoBg from './bg.mp4'
import videoBgPic from './bg.png'

const HeroBlock02 = ({content: {text, videobg}}) => (
    <Container title=''title='' sx={{textAlign: `center`}} className='homeHero'>
        <video className='videoBg'
               autoPlay loop muted
               style={{
                   position: `absolute`,
                   top: `50%`,
                   left: `50%`,
                   transform: `translate(-50%, -55%)`,
                   zIndex: -1,
                   size: 'full',
                   maxWidth: `100%`,
                   width: `100%`,
                   height: `100vh`,
                   objectFit: `cover`,
               }}
               poster={videoBgPic}>
            <source src={videoBg} type='video/mp4' />
        </video>
        <Reveal effect='fadeInDown'>
            <ContentText title='' sx={{display: `flex`, justifyContent: `center`, alignItems: `center`, textAlign: `center`}} content={text}/>
        </Reveal>
    </Container>
)

export default WithDefaultContent(HeroBlock02)


